import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Box } from 'rebass/styled-components'

import Container from './container'
import SectionTitle from './section-title'

const StyledMottoSection = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
`

const StyledSectionBody = styled(Box)`
  background-color: ${({ theme }) => rgba(theme.colors.darkGreen, 0.5)};
`

const MottoSection = () => {
  const data = useStaticQuery(graphql`
    query MottoBackground {
      landscape: file(relativePath: { eq: "landscape.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { fluid } = data.landscape.childImageSharp

  return (
    <StyledMottoSection Tag="section" fluid={fluid}>
      <StyledSectionBody py={[5, 6]} bg="darkGreen">
        <Container>
          <SectionTitle color="white" as="h2">Splňte si sen vlastního bydlení koupí pozemku pro svůj dům</SectionTitle>
          <SectionTitle color="white" as="h3" maxWidth="640px" fontSize={3} mb={0} mx="auto">Koupě pozemku  je nejlepší zhodnocení úspor a prostředek zachování hodnoty peněz</SectionTitle>
        </Container>
      </StyledSectionBody>
    </StyledMottoSection>
  )
}

export default MottoSection
