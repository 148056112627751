import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'rebass/styled-components'

const Card = ({ children, sx, ...props }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: 3,
      bg: 'white',
      boxShadow: 1,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Flex>
)

Card.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}

export default Card
