import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex, Text } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import SectionTitle from './section-title'
import Card from './card'
import ButtonLink from './button-link'
import IconElectricPlug from '../images/inline/electric-plug.svg'
import IconAutomobile from '../images/inline/automobile.svg'
import IconMoney from '../images/inline/money.svg'
import IconWalker from '../images/inline/walker.svg'
import IconBus from '../images/inline/bus.svg'

const iconSize = 72

const iconWrapperStyle = {
  width: iconSize,
  height: iconSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mb: 4,
  backgroundColor: 'white',
  borderRadius: iconSize,
}

const IconWrapper = ({ children }) => (
  <Box sx={iconWrapperStyle}>{children}</Box>
)

const SmallIconWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  margin: 0.5rem 0;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const Column = ({ children }) => (
  <Box width={[1, 1, '30%']} mb={[5, 5, 0]}>
    {children}
  </Box>
)

const FeatureSectionTitle = ({ children }) => (
  <SectionTitle
    fontSize={3}
    textAlign="left"
    minHeight={['none', 'none', '48px']}
  >
    {children}
  </SectionTitle>
)

const FeaturesSection = () => {
  return (
    <Section variant="light">
      <Container width="1080px">
        <SectionTitle as="h2">Vybavení pozemků</SectionTitle>

        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          mb={4}
        >
          <Column>
            <IconWrapper>
              <IconElectricPlug />
            </IconWrapper>

            <FeatureSectionTitle as="h3">
              Inženýrské sítě a vybavení pozemků
            </FeatureSectionTitle>

            <Text>
              Stavební pozemky jsou vybaveny přípojkami elektrické energie a
              napojeny na nově vybudovaný vodovodní řád a jednotnou kanalizaci.
              Příjezd k jednotlivým pozemkům je zajištěn po nově vybudovaných
              účelových komunikacích o šířce 4,5 m.
            </Text>
          </Column>

          <Column>
            <IconWrapper>
              <IconAutomobile />
            </IconWrapper>

            <FeatureSectionTitle as="h3">
              Příjezd k pozemkům
            </FeatureSectionTitle>

            <Text as="p" mb={3}>
              Stavební pozemky jsou přístupné ve směru od Kunčic pod Ondřejníkem
              a Čeladné po komunikaci č. 483 odbočkou do prava na Žuchově a z
              Frenštátu pod Radhoštěm po ulici Místecká s odbočkou do prava směr
              Žuchov.
            </Text>
          </Column>

          <Column>
            <IconWrapper>
              <IconMoney />
            </IconWrapper>

            <FeatureSectionTitle as="h3">Cena pozemků</FeatureSectionTitle>

            <Text as="p" mb={3}>
              Stavební pozemky nabízíme za cenu{' '}
              <strong>
                1&nbsp;590&nbsp;Kč/m<sup>2</sup> včetně DPH 21&nbsp;%.
              </strong>
            </Text>

            <Text>
              Ve spolupráci s našimi partnery nabízíme zajištění výhodného
              financování, vyřízení stavebního povolení a výstavbu rodinného
              domu dle našeho i Vašeho vlastního projektu za nejnižší možnou
              cenu.
            </Text>
          </Column>
        </Flex>

        <Flex maxWidth="80%" mx="auto" mb={5}>
          <Card width="100%">
            <Text as="p" mb={3}>
              V případě výstavby domu naši firmou je cena pozemku{' '}
              <strong>
                1&nbsp;426,-&nbsp;Kč za m<sup>2</sup> včetně DPH 15&nbsp;%.
              </strong>&nbsp;
            </Text>

            <ButtonLink to="/vystavba-domu">
              Informace o výstavbě domu
            </ButtonLink>
          </Card>
        </Flex>

        <Box>
          <SectionTitle as="h3" mb={4} fontSize={3}>
            Vzdálenost do Frenštátu pod Radhoštěm
          </SectionTitle>

          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              maxWidth: '780px',
              mx: 'auto',
            }}
          >
            <Card width="30%">
              2 minuty
              <SmallIconWrapper>
                <IconAutomobile />
              </SmallIconWrapper>
              2 km
            </Card>

            <Card width="30%">
              20 minut
              <SmallIconWrapper>
                <IconWalker />
              </SmallIconWrapper>
              2 km
            </Card>

            <Card width="30%">
              15 minut pěšky
              <SmallIconWrapper>
                <IconBus />
              </SmallIconWrapper>
              Zastávka Žuchov
            </Card>
          </Flex>
        </Box>
      </Container>
    </Section>
  )
}

Column.propTypes = {
  children: PropTypes.node,
}

FeatureSectionTitle.propTypes = {
  children: PropTypes.node,
}

IconWrapper.propTypes = {
  children: PropTypes.node,
}

export default FeaturesSection
