import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { Flex } from 'rebass/styled-components'

const primary = {
  py: 3,
  px: 4,
  bg: 'brown',
  color: 'white',
  fontFamily: 'body',
  fontWeight: 'bold',
  borderRadius: '5px',
  lineHeight: '2',
  textDecoration: 'none',
}

const white = {
  py: 3,
  px: 4,
  bg: 'white',
  color: 'brown',
  fontFamily: 'body',
  fontWeight: 'bold',
  borderRadius: '5px',
  lineHeight: '2',
  textDecoration: 'none',
}

const light = {
  py: 3,
  px: 4,
  bg: 'beige',
  color: 'brown',
  fontFamily: 'body',
  fontWeight: 'bold',
  borderRadius: '5px',
  lineHeight: '2',
  textDecoration: 'none',
}

const variants = {
  primary,
  white,
  light,
}

const linkStyle = {
  textDecoration: 'none',
  display: 'inline-block',
}

const ButtonLink = ({
  children, to, variant = 'primary', ...other
}) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink to={to} style={linkStyle} {...other}>
        <Flex sx={{
          ...variants[variant],
          display: 'inline-block',
          textAlign: 'center',
          justifyContent: 'center',
        }}
        >
          {children}
        </Flex>
      </GatsbyLink>
    )
  }

  return (
    <a href={to} style={linkStyle} {...other}>
      <Flex sx={{
        ...variants[variant],
        display: 'inline-block',
      }}
      >
        {children}
      </Flex>
    </a>
  )
}

ButtonLink.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  to: PropTypes.string,
}

export default ButtonLink
