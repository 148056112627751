import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { rgba } from 'polished'
import {
  Box, Flex, Text,
} from 'rebass/styled-components'

import Container from './container'
import SectionTitle from './section-title'
import IconPdf from '../images/inline/pdf.svg'
import IconDownload from '../images/inline/download.svg'


const StyledDownloadsSection = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
`

const StyledSectionBody = styled(Box)`
  background-color: ${({ theme }) => rgba(theme.colors.brown, 0.9)};
`

const downloadItemStyle = {
  bg: 'white',
  borderRadius: 3,
  width: '100%',
  // px: 3,
  // py: 2,
  color: 'text',
  alignItems: 'center',
  boxShadow: 1,
}

const DownloadItem = ({ children }) => (
  <Flex sx={downloadItemStyle} mb={3}>{children}</Flex>
)

const Data = styled(Flex)``

const StyledIconPdf = styled(IconPdf)`
  margin-right: 0.5rem;
`

const StyledIconDownload = styled(IconDownload)`
  margin-left: 0.5rem;
`

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: bold;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  border-radius: 0 3px 3px 0;
  height: 100%;

  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.colors.brown};
    color: ${(props) => props.theme.colors.white};
  }

  ${StyledIconDownload} {
    color: ${(props) => props.theme.colors.brown};
    fill: currentColor;
    transition: all 200ms ease-in-out;
  }

  &:focus ${StyledIconDownload},
  &:hover ${StyledIconDownload} {
    color: ${(props) => props.theme.colors.white};
  }
`

const DownloadsSection = ({ downloads, theme }) => {
  const data = useStaticQuery(graphql`
    query DownloadsBackground {
      papers: file(relativePath: { eq: "papers.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { fluid } = data.papers.childImageSharp

  return (
    <StyledDownloadsSection Tag="section" fluid={fluid} backgroundColor={theme.colors.brown}>
      <StyledSectionBody py={[4, 5]} color="white">
        <Container>
          <SectionTitle as="h2" color="white">Ke stažení</SectionTitle>

          <Flex maxWidth="720px" mx="auto" flexDirection="column">
            {downloads && downloads.map((item) => (
              <DownloadItem key={item.file.publicURL}>
                <Data sx={{ py: 2, px: 3 }}>
                  <StyledIconPdf />
                  <Text>{item.name}</Text>
                </Data>

                <DownloadLink href={item.file.publicURL} target="_blank" style={{ padding: '8px 16px' }}>
                  Stáhnout <StyledIconDownload />
                </DownloadLink>
              </DownloadItem>
            ))}
          </Flex>
        </Container>
      </StyledSectionBody>
    </StyledDownloadsSection>
  )
}

DownloadsSection.propTypes = {
  downloads: PropTypes.array,
  theme: PropTypes.object,
}

DownloadItem.propTypes = {
  children: PropTypes.node,
}

export default withTheme(DownloadsSection)
