import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components'
import BackgroundImage from 'gatsby-background-image'

import CzMap from '../images/inline/czech-republic-outline.svg'
import Container from './container'

const SlideshowWrapper = styled(Box)`
  width: 100%;
`

const StyledBackgroundSlide = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const SlideBody = styled(Flex)``

const MapWrapper = styled(Box)`
  margin-bottom: 2rem;
`

const Slideshow = () => {
  const data = useStaticQuery(graphql`
    query {
      slide1: file(relativePath: { eq: "slide-parcely.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const slide1 = data.slide1.childImageSharp.fluid

  return (
    <SlideshowWrapper>
      <StyledBackgroundSlide
        Tag="div"
        fluid={slide1}
        backgroundColor="#040e18"
      >
        <Container>
          <SlideBody flexDirection="column" alignItems="flex-start" width={[1, 1, 2 / 3]} py={6}>
            <MapWrapper>
              <CzMap />
            </MapWrapper>

            <Heading as="h1" mb={3} color="white" fontSize={[4, 5, 7]}>Stavební pozemky v&nbsp;podhůří Beskyd</Heading>

            <Text color="white" lineHeight="1.5" fontWeight="bold" fontSize={[2, 3, 4]}>Obec Tichá—Frenštát pod Radhoštěm</Text>
          </SlideBody>
        </Container>
      </StyledBackgroundSlide>
    </SlideshowWrapper>
  )
}

export default Slideshow
