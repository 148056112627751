import React from 'react'

import Section from './section'
import Container from './container'
import SectionTitle from './section-title'
import contoursBg from '../images/contours-background.svg'

const IntroSection = () => (
  <Section
    variant="dark"
    sx={{
      backgroundImage: `url(${contoursBg})`,
      backgroundSize: 'cover',
    }}
  >
    <Container>
      <SectionTitle
        as="h2"
        color="white"
        fontSize="4rem"
        mb="0"
        sx={{ transform: 'rotate(-3deg)' }}
      >
        Prodej pozemků ukončen
      </SectionTitle>
    </Container>
  </Section>
)

export default IntroSection
