/* eslint-disable class-methods-use-this */
import React from 'react'
import { PropTypes } from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { InView } from 'react-intersection-observer'

import markerImage from '../images/marker.svg'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.beige};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  ${(props) => props};
`

class SeznamMapa extends React.Component {
  constructor(props) {
    super(props)

    this.mapNode = React.createRef()
    this.createMap = this.createMap.bind(this)
    this.state = {
      mapLoaded: false,
    }
  }

  loadScript() {
    const script = document.createElement('script')
    script.src = 'https://api.mapy.cz/loader.js'
    script.async = true
    window.document.head.appendChild(script)

    script.addEventListener('load', () => {
      if (typeof window.Loader !== 'undefined') {
        this.setState({
          mapLoaded: true,
        })
        window.Loader.async = true
        window.Loader.load(null, null, this.createMap)
      }
    })
  }

  createMap() {
    const center = window.SMap.Coords.fromWGS84(
      Number(this.props.lng),
      Number(this.props.lat),
    )
    const mapa = new window.SMap(
      window.JAK.gel('mapa'),
      center,
      this.props.zoom || 15,
    )

    const mouseProps = window.SMap.MOUSE_PAN || window.SMap.MOUSE_ZOOM
    const mouseControl = new window.SMap.Control.Mouse(mouseProps)
    const zoomProps = { showZoomMenu: true }
    const zoomControl = new window.SMap.Control.Zoom(null, zoomProps)
    const markerLayer = new window.SMap.Layer.Marker()
    const markerDiv = window.JAK.mel('div')
    const markerImg = window.JAK.mel('img', {
      src: markerImage,
      width: 30,
      height: 30,
    })
    markerDiv.style.width = '30px'
    markerDiv.appendChild(markerImg)

    const marker = new window.SMap.Marker(center, null, {
      url: markerDiv,
      size: [30, 30],
      anchor: { left: 15, top: 30 },
      title: this.props.title,
    })

    markerLayer.addMarker(marker)

    mapa.addDefaultLayer(window.SMap.DEF_BASE).enable()

    mapa.addControl(mouseControl)
    mapa.addControl(zoomControl, { right: '8px', top: '9px' })
    mapa.addLayer(markerLayer)
    markerLayer.enable()
  }

  render() {
    return (
      <InView
        onChange={(inView) => {
          if (inView) {
            this.loadScript()
          }
        }}
      >
        {({ ref }) => (
          <div ref={ref}>
            <Wrapper {...this.props.containerStyle}>
              {this.state.mapLoaded && (
                <div
                  id="mapa"
                  ref={this.mapNode}
                  style={{ width: '100%', height: '100%' }}
                />
              )}

              {!this.state.mapLoaded && <span>Načítám mapu...</span>}
            </Wrapper>
          </div>
        )}
      </InView>
    )
  }
}

SeznamMapa.propTypes = {
  containerStyle: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  title: PropTypes.string,
}

export default withTheme(SeznamMapa)
