import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Box, Flex, Text,
} from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import SectionTitle from './section-title'
import SeznamMapa from './map'
import ButtonLink from './button-link'

const LocationSection = ({ mapLink }) => {
  const data = useStaticQuery(graphql`
    query LocationSectionQuery {
      site {
        siteMetadata {
          project {
            lat
            lng
            address {
              street
              city
            }
          }
        }
      }
    }
  `)

  const { project } = data.site.siteMetadata
  const map = {
    lat: project.lat,
    lng: project.lng,
    address: `${project.address.street}, ${project.address.city}`,
  }


  return (
    <Section>
      <Container>
        <SectionTitle as="h2">Lokalita pozemků</SectionTitle>

        <Flex flexDirection={['column', 'row']} justifyContent="space-between">
          <Box width={[1, '48%']} mb={[4, 0]}>
            <Text
              as="p"
              fontSize={4}
              mb={4}
              pl={4}
              sx={{
                borderLeftColor: 'brown',
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
              }}
            >
              {project.address.street}
              <br />
              {project.address.city}
            </Text>

            <Text as="p" mb={4}>
              Lokalita se nachází na rozhraní obcí Frenštátu pod Radhoštěm a
              Tichá nedaleko místa zvaného Žuchov. Jedná se o slunné místo s
              krásnými výhledy na panorama Beskyd a okolních vrcholů.
            </Text>

            {mapLink && (
              <ButtonLink variant="primary" href={mapLink} target="_blank" rel="noopener noreferrer">
                Zobrazit na mapy.cz
              </ButtonLink>
            )}
          </Box>

          <Box width={[1, 1 / 2]}>
            {map && (
              <SeznamMapa
                lat={map.lat}
                lng={map.lng}
                center={[Number(map.lng), Number(map.lat)]}
                zoom={12}
                containerStyle={{
                  height: '50vh',
                  width: '100%',
                }}
                title={map.address}
              />
            )}
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

LocationSection.propTypes = {
  mapLink: PropTypes.string,
}

export default LocationSection
