import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Slideshow from '../components/slideshow'
import IntroSection from '../components/intro-section'
import LocationSection from '../components/location-section'
import MottoSection from '../components/motto-section'
import FeaturesSection from '../components/features-section'
import DownloadsSection from '../components/downloads-section'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      markdownRemark(frontmatter: { pageType: { eq: "index" } }) {
        frontmatter {
          mapLink
          downloads {
            name
            file {
              publicURL
            }
          }
        }
      }
    }
  `)
  const { downloads, mapLink } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO title="Informace" />

      <Slideshow />

      <IntroSection />

      <LocationSection mapLink={mapLink} />

      <MottoSection />

      <FeaturesSection />

      <DownloadsSection downloads={downloads} />
    </Layout>
  )
}

export default IndexPage
